
.goods-list-info {
  display: flex;
  .goods-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-all;
    margin-left: 10px;
  }
}

.batch-handle {
  margin-bottom: 16px;
  button {
    margin-right: 20px;
  }
}
.goods-sku-box {
  display: flex;
  flex-flow: wrap;
  margin-left: 76px;
  .goods-sku-item {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #efefef;
    .sku-name {
      -webkit-line-clamp: 2;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-all;
    }
    .sku-param-item {
      span {
        margin-right: 10px;
      }
    }
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;