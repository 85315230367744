.goods-list-info {
  display: flex;
}
.goods-list-info .goods-name {
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
  margin-left: 10px;
}
.batch-handle {
  margin-bottom: 16px;
}
.batch-handle button {
  margin-right: 20px;
}
.goods-sku-box {
  display: flex;
  flex-flow: wrap;
  margin-left: 76px;
}
.goods-sku-box .goods-sku-item {
  width: 300px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #efefef;
}
.goods-sku-box .goods-sku-item .sku-name {
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-all;
}
.goods-sku-box .goods-sku-item .sku-param-item span {
  margin-right: 10px;
}
